@import "./theme/colors.scss";

#root {
  min-height: 100vh;
  width: 100%;
}

@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/Poppins/Poppins-Thin.ttf");
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/Poppins/Poppins-ExtraLight.ttf");
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: semi-bold;
  font-weight: 600;
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: bold;
  font-weight: 700;
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: semi-bold;
  font-weight: 800;
  src: url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf");
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  font-style: extra-bold;
  font-weight: 900;
  src: url("./assets/fonts/Poppins/Poppins-Black.ttf");
}
body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $color-dark;
  -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
  scrollbar-width: none; /*Hide scrollbar for Firefox */
}
// body::-webkit-scrollbar {
//   display: none; /* Hide scrollbar for Chrome, Safari and Opera */
// }
code {
  font-family: Poppins;
}
.form-footer-txt {
  text-decoration: none;
  color: $color-primary;
  font-size: 1rem;
  color: #95628d;
}
.MuiInput-underline:before {
  border-bottom: none !important;
}

@media screen and(max-width:600px) {
  .MuiGrid-container {
    justify-content: center !important;
  }
}

.MuiInput-underline:before {
  border-bottom: 0px solid $color-secondary !important;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 0px 10px;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.flex-space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.flex-align-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.flex-align {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.margin-bothSide {
  margin: 0px 15px;
}
.font-style {
  color: white;
  font-weight: bold;
}
.set-containerStyle {
  height: 400px;
  font-size: 23px;
}

a {
  text-decoration: none;
}

.form-card {
  padding: 0px !important;
}

.padding-lrb-20 {
  padding: 0px 20px 20px 20px;
}

.card-style {
  width: 100%;
  border-radius: 12px 0 0 0;
}
.flex-div {
  display: flex;
  flex-direction: column;
}

.form-button {
  margin-top: 50px;
  padding: 15px;
}
.wrapped-title {
  max-width: min-content !important;
  font-size: 48px !important;
}
.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: $color-secondary;
  -webkit-box-shadow: 0 0 0px 1000px $color-dark inset;
  box-shadow: 0 0 0px 1000px $color-dark inset;
  transition: background-color 5000s ease-in-out 0s;
}

.nursePageContainer {
  padding: 0 15px;
  color: white;
}
.patientContainer {
  padding: 0 15px;
}
.content {
  .MuiTypography-h3 {
    font-size: 35px;
  }
}
.common-heading-style {
  font-weight: bold;
  font-size: 23px;
  text-align: center;
  padding: 10px;
}
#comman-container-box {
  margin-bottom: 10px;
  .d-flex {
    display: flex;
  }
  .fieldName {
    width: 90px;
    text-transform: capitalize;
    font-weight: bold;
  }
  .fieldValue {
    text-transform: capitalize;
  }
}
.no-data {
  text-align: center;
  margin: 15px 0px;
  color: gray;
}
#patient-chart {
  max-height: 80%;
  overflow: auto;
  background: #1e2024;
  border-radius: 5px;
  margin: auto 10vw;
  padding: 10px 25px;
  color: white;
  .patientChart-height {
    height: 370px;
    overflow-y: auto;
  }
}
.button-container {
  .btn {
    width: 140px !important;
    margin-top: 34px;
  }
}

.MuiDrawer-docked {
  flex: 0 0 auto;
  display: table;
}

.billing .card-btn {
  width: 270px !important;
  background: linear-gradient(
    229.34deg,
    $modal-btn-gradient1 8.35%,
    $modal-btn-gradient2 89.61%
  );
  border-radius: 6px;
}
.card-form {
  .MuiTypography-gutterBottom {
    color: $color-secondary;
  }
  #pill-input .MuiInputBase-root {
    border: 1px solid $field-border !important;
  }
}
.hide-scroll {
  -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
  scrollbar-width: none; /*Hide scrollbar for Firefox */
}
.hide-scroll::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
#plan-container {
  background-color: #1e2024;
  padding: 25px;
  .set-Container {
    height: 425px;
    overflow-y: auto;
    position: relative;
    margin-bottom: 10px;
    -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
    scrollbar-width: none; /*Hide scrollbar for Firefox */
  }
  .set-Container::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
  .main-title {
    font-size: 28px;
    color: white;
    text-align: center;
    font-weight: bold;
  }
  .m-bottom {
    margin-bottom: 25px;
  }
}
.set-bg {
  background: linear-gradient(360deg, #ba54f5 0%, #e14eca 100%) !important;
}
.text-bold {
  font-weight: bold;
}
.headerSize {
  font-size: 48px !important;
}
.highlightCard {
  box-shadow: 0px 0px 9px rgba (209, 198, 198, 0.07) !important;
}
$i: 0;
@while $i<=50 {
  .font-#{$i} {
    font-size: #{$i}px !important;
  }
  $i: $i + 1;
}

$i: 0;
@while $i<=20 {
  .margin-right-#{$i} {
    // margin-right: #{$i}px !important;
  }
  $i: $i + 1;
}

$i: 0;
@while $i<=100 {
  .width-#{$i} {
    width: #{$i}#{"%"} !important;
  }
  $i: $i + 10;
}
.hide-visibilty {
  visibility: hidden;
}

#change-password-setting {
  display: flex;
  flex-direction: column;
  text-align: center;
  .button {
    width: fit-content !important;
  }
  .table-container {
    padding: 10px;
    width: calc(100% - 20px);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 80px;
  }
}
#viewProfile {
  .profile-container {
    margin: 10px 10px;
  }
  .profile-header {
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
    color: white;
    text-transform: capitalize;
  }
  .profile-value {
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
    color: white;
    padding: 5px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    border-color: #2b3553;
  }
}

@media screen and (min-width: 600px) {
  .root-form-container {
    min-height: 100vh;
  }
  .container {
    position: relative;
  }
  .SignUp-credit-card {
    position: absolute;
    bottom: 55%;
    right: calc(12% - 4rem);
  }

  .billing .card-btn {
    position: absolute;
    right: calc(5% - 0.1rem) !important;
    bottom: 48% !important;
  }
}

.billing-form-root {
  padding: 9% 20%;
}
@media screen and (max-width: 600px) {
  .billing-form-root {
    padding: 9% 20%;
  }
  .rnc__notification-container--top-right {
    right: 0 !important;
    top: 0 !important;
  }
  .container {
    min-height: 100vh;
  }
  .app-logo {
    width: 224px !important;
  }
  .SignUp-credit-card .rccs__card {
    height: 182.873px;
    margin: 0;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all 0.4s linear;
    width: 270px;
  }
}

@media screen and (max-width: 480px) {
  .content {
    text-align: center;
    margin-top: 30px;
  }
}
.requestCall {
  display: flex;
  justify-content: center;
}
.notifications-component {
  top: 0;
}
.videoCallBox {
  position: relative;
  background: #1e2024;
  color: white;
  padding: 20px;
  border-radius: 5px;
  min-width: 75%;
  display: flex;
  justify-content: center;
  margin: 0px 20px;
}

.MuiInputAdornment-positionEnd {
  margin-right: 5px;
  cursor: pointer;
}
.m-10 {
  margin: 10px 0px;
}
.m-top-5 {
  margin-top: 5px;
}
.p-tb-10 {
  padding: 10px 0px;
  text-align: center;
}
.hide {
  visibility: hidden;
}
.status-btn {
  font-size: 15px;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: bolder;
  text-align: center;
  width: fit-content;
  color: white;
}
.pending {
  background: linear-gradient(229.34deg, #ff9800eb 60.35%, #ffeb3bc2 99%);
}
.accepted {
  background: linear-gradient(229.34deg, #079607 50.35%, #8bc34a 80%);
}
.rejected {
  background: linear-gradient(228.34deg, #ea3c03 25.35%, #f77e75e0 90%);
}
.other {
  background: linear-gradient(228.34deg, #a883ea 25.35%, #2196f3 90%);
}
.full-container {
  width: 100%;
  color: white;
}

input[name="fileInfo"] {
  padding-left: 20px;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.no-new-plan {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient-call-box {
  position: relative;
  width: 75%;
  margin: auto;
  border: 1px solid gray;
  border-radius: 5px;
}
.MuiInputBase-input.Mui-disabled {
  opacity: 0.7 !important;
}

.no-doc {
  text-align: center;
  margin: 15px 0px;
  color: gray;
}
.yes-no-btn {
  text-transform: uppercase;
  font-weight: bold;
}

.modal-history {
  .patientchart {
    min-width: calc(100% - 50px);
  }
}

.modal-videocall {
  .modal-text-beside-sidebar {
    min-width: calc(100% - 60px);
  }
}
.modal-video-connect {
  .videoCallBox {
    min-width: calc(100% - 40px);
  }
}

.modal-forget {
  .modal-text-beside-sidebar {
    min-width: calc(100% - 60px);
  }
}


#root {
  position: relative;
}