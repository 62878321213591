$color-primary: #e14eca;
$color-primary-dark: #ba54f5;
$color-secondary: #ffffff;
$color-dark: #141517;
$color-grey: rgba(0, 0, 0, 0.8);
$color-grey1: #27293d;
$color-error: red;
$gradient1: #4af1d3;
$gradient2: #08a1ed;
$tick-success: #5cb85c;
$modal-btn-gradient1: #00f2c3;
$modal-btn-gradient2: #0098f0;
$card-gradient1: #344675;
$card-gradient2: #263148;
$card-gradient3: #ba54f5;
$card-gradient4: #e14eca;
$color-success: #00f2c3;
$color-warning: #ff8d72;
$color-danger: #fd5d93;
$color-success-dark: #0098f0;
$color-warning-dark: #ff6491;
$color-danger-dark: #ec250d;
$color-primary-light: rgba(225, 78, 201, 0.1);
$color-success-light: rgba(0, 242, 195, 0.1);
$color-warning-light: rgba(255, 141, 114, 0.1);
$color-danger-light: rgba(253, 93, 147, 0.1);
$progress-bg: #141430;
$field-border: #2b3553;
$primary-text: #9a9a9a;
$color-order-card: #419ef9;
$color-table-border: rgba(227, 227, 227, 0.1);

:export {
  primaryColor: $color-primary;
  primaryDarkColor: $color-primary-dark;
  secondaryColor: $color-secondary;
  darkColor: $color-dark;
  greyColor: $color-grey;
  gradient1: $gradient1;
  gradient2: $gradient2;
  tickSuccess: $tick-success;
  modalBtnGradient1: $modal-btn-gradient1;
  modalBtnGradient2: $modal-btn-gradient2;
  colorSuccess: $color-success;
  colorWarning: $color-warning;
  colorDanger: $color-danger;
  colorSuccessDark: $color-success-dark;
  colorWarningDark: $color-warning-dark;
  colorDangerDark: $color-danger-dark;
  colorPrimaryLight: $color-primary-light;
  colorSuccessLight: $color-success-light;
  colorWarningLight: $color-warning-light;
  colorDangerLight: $color-danger-light;
  progressBg: $progress-bg;
  fieldBorder: $field-border;
  primaryText: $primary-text;
  colorGrey1: $color-grey1;
  colorOrderCard: $color-order-card;
  colorTableBorder: $color-table-border;
}
