@import "../../theme/colors.scss";

#pill-input textarea {
  margin: 1rem 0;
  background: transparent;
  padding: 10px;
  border-radius: 4px;
  max-height: 10vh;
  min-height: 7vh;
  font-size: 17px;
}
#full-input-field {
  .MuiInputBase-root {
    border: 1px solid #707070 !important;
    border-radius: 4px !important;
    padding: 0px 2px 0px 15px;
    margin: 15px 0px;
    height: 40px;
    mix-blend-mode: normal;
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
  }

  .error-text {
    color: $color-error;
    white-space: pre-line;
    font-size: 0.8128rem;
    text-align: end;
    text-transform: capitalize;
    padding-top: 0px;
    display: flex;
  }
  .visibility-icon {
    svg {
      color: #8a8787 !important;
    }
  }
  .custom-placeholder {
    position: absolute;
    margin-top: 23px;
    background: white;
    color: rgba(0, 0, 0, 0.42);
    font-size: 4rem;
    padding: 0 20px 0 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.075px;
    color: #9a9a9a;
  }
}
#pill-input {
  .error-text {
    color: $color-error;
    white-space: pre-line;
    font-size: 0.8128rem;
    text-align: end;
    text-transform: capitalize;
    padding-top: 0px;
    display: flex;
  }
  .visibility-icon {
    svg {
      color: #8a8787 !important;
    }
  }
  .custom-placeholder {
    position: absolute;
    margin-top: 23px;
    background: white;
    color: rgba(0, 0, 0, 0.42);
    font-size: 4rem;
    padding: 0 20px 0 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.075px;
    color: #9a9a9a;
  }
  .MuiInputBase-root {
    border: 1px solid #707070 !important;
    border-radius: 8px !important;
    padding: 0px 2px 0px 30px;
    margin: 15px 0px;
    height: 40px;
    mix-blend-mode: normal;
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
  }
  @media screen and(max-width:768px) {
    .MuiInputBase-root {
      width: 100%;
    }
  }
}

.sign-up #pill-input {
  .MuiInputBase-root {
    height: 40px;
    // border: 1px solid $field-border !important;
  }
}

.upload #pill-input .MuiInputBase-root {
  border: 1px solid $field-border !important;
  color: $color-secondary;
}

#input-field .error-text {
  color: red;
  white-space: pre-line;
  font-size: 15px;
  padding-top: 5px;
}
#input-field .field-container {
  display: inline-flex;
}
.state-selection {
  border: 1px solid #2b3553 !important;
  padding: 0px 2px 0px 10px !important;
  height: 35px;
  option {
    color: black;
  }
}

.file-picker-field {
  border: 1px solid #707070;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  position: relative;
}

.upload-icon {
  position: absolute;
  right: 0;
  z-index: 1;
}
@media screen and(max-width:800px) {
  .file-picker-field {
    width: 300px;
    margin: 1rem 0;
  }
}

@media screen and(max-width:400px) {
  .file-picker-field {
    width: 250px;
    margin: 1rem 0;
  }
}

.file-picker-field img {
  width: 20px;
  padding: 10px;
  object-fit: contain;
}

.file-picker-field input {
  color: #cec8c8;
  font-size: 17px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 6px 0 10px 10px;
  width: inherit;
}
