.loading {
  .MuiPaper-root {
    background-color: transparent;
  }
  .MuiDialog-paper {
    margin: 32px;
    position: relative;
    overflow-y: unset;
  }
  .MuiPaper-elevation24 {
    box-shadow: none;
  }
}

.loader-container {
  width: 200px;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 100%;
  align-items: center;
}
.loader-gif {
  width: 100%;
  height: 100%;
  background: transparent;
}
